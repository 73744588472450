import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#dcd7cd',
      main: '#e5b666',
      dark: '#41403c'
    },
    secondary: {
      light: '#3c6742',
      main: '#41403c',
      dark: '#0f2913'
    },
    background: {
      paper: '#fff',
      default: '#f5f3ec'
    },
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontFamily: `'PT Serif', serif`,
    button: {
      fontFamily: `'PT Serif', serif`,
      textTransform: 'lowercase',
      letterSpacing: '1px',
      fontSize: '1.05em'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      }
    }
  }
})