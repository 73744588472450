import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import GuestContext from "../GuestContext";
import { GUEST_DATA, LOCALSTORAGE_KEY } from "../constants";
import { niceCoupleName } from "../utils";
import Photo from "./Photo";

const ThankYou = props => {

  const { guest, setGuest } = useContext(GuestContext);
  const [guestData, setGuestData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('g')) await localStorage.setItem(LOCALSTORAGE_KEY, searchParams.get('g'));
      const data = await fetch(GUEST_DATA);
      const json = await data.json();
      setGuestData(json);
    })();
  }, []);

  useEffect(() => {
    if (guestData?.guests) {
      (async () => {
        const storedGuestId = await localStorage.getItem(LOCALSTORAGE_KEY);
        if (storedGuestId) {
          const match = guestData.guests.filter(guestObj => guestObj.id === parseInt(storedGuestId));
          if (match.length) setGuest(match[0]);
        } else setGuest({});
      })();
      setTimeout(() => setLoaded(true), 500);
    }
  }, [guestData, setGuest]);

  return (
    <>
      {!loaded && <Box display="flex" justifyContent="center" mt={6} mb={8}><CircularProgress /></Box>}
      {loaded &&
        <Grid container spacing={4} mt={1} justifyContent="center">
          <Grid item xs={12} sm={4} display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h2" color="primary" mt={-2} mb={2} fontSize={{ xs: '2.75em' }}>Thank you 🥂</Typography>
            <Typography variant="h6">We had the best day, and a huge part of that was down to you being there.</Typography>
          </Grid>
          {guest?.id &&
            <Grid item display="flex" xs={12} pb={{ xs: 4 }} sm={4} justifyContent='center'>
              <Photo src={`img/guests/guest_${guest.id}.jpeg`} caption={niceCoupleName(guest)} rotate={-5} />
            </Grid>}
          {!guest?.id &&
            <Grid item display="flex" xs={12} pb={{ xs: 4 }} sm={4} justifyContent='center'>
              <Photo src={`img/couple.jpeg`} caption={"The Locketts"} rotate={-5} noEffect={true} />
            </Grid>}
        </Grid>
      }
    </>
  )
}

export default ThankYou;