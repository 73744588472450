import { Container, Toolbar } from "@mui/material";
import BridalParty from "../components/BridalParty";
import Contact from "../components/Contact";
import EllieAndy from "../components/EllieAndy";
import Footer from "../components/Footer";
import Stay from "../components/Stay";
import TheDay from "../components/TheDay";
import Travel from "../components/Travel";
import ThankYou from "../components/ThankYou";
import { POST_EVENT_MODE } from "../constants";
import Upload from "../components/Upload";

const MainContent = () => (
  <Container maxWidth="lg" sx={{ p: 3, background: '#fffefb', overflowX: 'hidden' }}>
    {!POST_EVENT_MODE && <Toolbar />}
    <EllieAndy />
    {POST_EVENT_MODE && <Upload />}
    {POST_EVENT_MODE && <ThankYou />}
    {!POST_EVENT_MODE && <TheDay />}
    {!POST_EVENT_MODE && <Travel />}
    {!POST_EVENT_MODE && <Stay />}
    {!POST_EVENT_MODE && <BridalParty />}
    {!POST_EVENT_MODE && <Contact />}
    <Footer />
  </Container>
)

export default MainContent