import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Photo = props => {
  const { src, caption, rotate, noEffect } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => setLoaded(true);
  }, [src]);

  return (
    <Paper sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: { xs: '170px', md: '220px', xl: '240px' },
      height: { xs: '200px', md: '260px', xl: '280px' },
      border: '10px solid #fff',
      background: '#fff',
      transform: `rotate(${rotate}deg)`
    }
    }>
      {loaded ?
        <Box component="img" alt={caption} src={src} sx={{
          width: '100%',
          filter: !noEffect ? 'sepia(50%)' : 'none',
        }} />
        :
        <CircularProgress sx={{ margin: '2em 0' }} />
      }
      <Typography variant="body" fontStyle="italic" textAlign="center" sx={{ fontSize: '0.9em', mt: 1 }}>
        {caption}
      </Typography>
    </Paper>
  )
}

export default Photo;