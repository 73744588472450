import { FavoriteBorder } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <FavoriteBorder sx={{ color: theme.palette.primary.light }} />
    </Box>
  )
}

export default Footer;