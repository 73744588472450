export const API_ENDPOINT = 'https://api.lockettwedding.com';
export const GUEST_DATA = '/guests.json';
export const LOCALSTORAGE_KEY = 'GUEST_ID';
export const RSVP_INITIAL_CODE_STATE = { "0": "", "1": "", "2": "", "3": "" };
export const RSVP_PASS = 'db189a761f64846ea0b1f9677e8657dffab0113d7eafa135eff52d479a56a24c';
export const DEFAULT_IMAGE_SRC = '/img/couple.jpeg';
export const DIET_REQS = [
  { key: 'None', label: 'No dietary requirements' },
  { key: 'DF', label: 'Dairy free' },
  { key: 'GF', label: 'Gluten free' },
  { key: 'VE', label: 'Vegetarian' },
  { key: 'VG', label: 'Vegan' },
];
export const HAS_MEAL_OPTS = true;
export const MEAL_OPTS = [
  { key: 'LAMB', label: 'Lamb Shoulder', desc: 'with pommes anna, confit vegetables and jus.' },
  { key: 'CHICKEN', label: 'Roasted Chicken', desc: 'with chicken beignet, pancetta fricassee, pommes anna and jus.' }
];
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const POST_EVENT_MODE = true;