import { Box, Typography, keyframes, useTheme } from "@mui/material";

const EllieAndy = () => {
  const theme = useTheme();

  const eclipseRotation = {
    keyframes: keyframes`
    0% {
      transform: rotate(0deg);
      border-color: ${theme.palette.primary.main};
    }
    50% {
      transform: rotate(-180deg);
      border-color: #f3d455;
    }
    100% {
      transform: rotate(-360deg);
      border-color: ${theme.palette.primary.main};
    }`,
    duration: 30000
  };

  const Name = props => {
    const { name, pr, pl } = props;
    return (
      <Typography sx={{
        fontFamily: `'Quicksand', sans-serif`,
        fontSize: { xs: '3em', sm: '4em', md: '4.5em', xl: '5em' },
        textTransform: 'uppercase',
        flex: '0 1',
        pr: pr,
        pl: pl,
        color: theme.palette.primary.dark
      }} variant="h1">{name}</Typography>
    )
  }

  return (
    <Box sx={{ mt: { xs: 1, sm: 3 }, pb: { xs: 6, sm: 8, md: 10, xl: 10 }, overflow: 'hidden', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Box sx={{
        width: { xs: '340px', sm: '450px', md: '500px', xl: '600px' },
        height: { xs: '340px', sm: '450px', md: '500px', xl: '600px' },
        display: 'block',
        borderRadius: { xs: '175px', sm: '225px', md: '250px', xl: '300px' },
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: { xs: '3px', sm: '4px' },
        mb: { xs: '-255px', sm: '-330px', md: '-370px', xl: '-440px' },
        position: 'relative',
        animation: `${eclipseRotation.keyframes} ${eclipseRotation.duration}ms linear 0s infinite`
      }}>
        <Box component="div" sx={{
          width: { xs: '310px', sm: '390px', md: '420px', xl: '500px' },
          height: { xs: '340px', sm: '460px', md: '520px', xl: '600px' },
          bottom: { xs: '-50px', sm: '-60px', md: '-80px', xl: '-80px' },
          right: { xs: '-60px', sm: '-60px', md: '-70px', xl: '-70px' },
          position: 'absolute',
          background: `radial-gradient(#fffefb 65%, rgba(255,255,255,0) 75%)`,
        }} />
      </Box>
      <Box sx={{ zIndex: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Name name="Eleanor" pr={{ xs: 5, sm: 6, md: 8, xl: 10 }} />
        <Typography sx={{
          flex: '0 1',
          pb: 1,
          mt: { xs: -3, sm: -4, md: -5 },
          mb: { xs: -3, sm: -4, md: -5 },
          color: theme.palette.primary.light,
          fontSize: { xs: '5em', sm: '7.5em', md: '8.5em', xl: '10em' },
          opacity: 0.5
        }} variant="h1">&amp;</Typography>
        <Name name="Andrew" pl={{ xs: 5, sm: 6, md: 8, xl: 10 }} />
        <Typography variant="h2"
          sx={{
            fontSize: { xs: '1em', sm: '1.25em', md: '1.5em', xl: '2em' },
            color: theme.palette.primary.main,
            mt: 4
          }}>
          08.03.24
        </Typography>
      </Box>
    </Box>
  )
}

export default EllieAndy;