import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import GuestContext from './GuestContext';
import DrawerAppBar from './components/DrawerAppBar';
import MainContent from './containers/MainContent';
import theme from './theme';
import { useState } from 'react';
import { POST_EVENT_MODE } from './constants';

const App = () => {
  const [guest, setGuest] = useState(null)

  return (
    <ThemeProvider theme={theme}>
      <GuestContext.Provider value={{ guest, setGuest }}>
        <CssBaseline />
        {!POST_EVENT_MODE && <DrawerAppBar />}
        <MainContent />
      </GuestContext.Provider>
    </ThemeProvider>
  );
}

export default App;
