import { CameraAltSharp, CheckCircle } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, Grid, Input, InputLabel, useTheme } from "@mui/material";
import { createRef, useState } from "react";
import { API_ENDPOINT, LOCALSTORAGE_KEY } from "../constants";

const Upload = props => {

  const theme = useTheme();
  const uploadFieldRef = createRef();

  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [currentFile, setCurrentFile] = useState(0);
  const [uploadsComplete, setUploadsComplete] = useState(false);

  const filesChanged = event => {
    setFiles(event.target.files);
  }

  const uploadProgress = () => (currentFile / files.length) * 100;

  const resetForm = () => {
    setFiles([]);
    setSubmitting(false);
    setCurrentFile(0);
    setUploadsComplete(false);
    uploadFieldRef.current.value = "";
  };

  const startUpload = async event => {
    setSubmitting(true);
    for (let key = 0; key < files.length; key++) {
      setCurrentFile(key + 1);
      const signedRequestData = await fetch(`${API_ENDPOINT}/upload`, {
        method: 'GET',
        headers: {
          guestid: localStorage.getItem(LOCALSTORAGE_KEY) || "",
          file: files[key].name,
          filecontenttype: files[key].type
        }
      });
      const signedRequest = await signedRequestData.json();
      try {
        await uploadFile(files[key], signedRequest);
      } catch (e) {
        console.warn("Failed uploading file", files[key].name, e.message);
      }
    }
    finishedUploads();
  }

  const uploadFile = (file, signedRequest) => new Promise(async (res, rej) => {
    const formData = new FormData();
    for (let key in signedRequest.fields) {
      formData.append(key, signedRequest.fields[key]);
    }
    formData.append("file", file);
    try {
      const response = await fetch(signedRequest.url, {
        method: 'POST',
        body: formData
      });
      if (response.status === 201) res();
      else rej(new Error(`Received status ${response.status} from S3`));
    } catch (e) {
      rej(e);
    }
  });

  const finishedUploads = async () => {
    setUploadsComplete(true);
    const notifyResp = await fetch(`${API_ENDPOINT}/notify`, {
      method: 'POST',
      body: JSON.stringify({ 
        notify: "true",
        guestid: localStorage.getItem(LOCALSTORAGE_KEY) || "",
        count: files.length
      })
    });
    await notifyResp.json();
  };

  return (
    <Grid container spacing={2} mt={{ xs: 0, sm: 3 }} justifyContent="center">
      <Grid item xs={0} sm={3} />
      <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <FormControl fullWidth sx={{ display: !submitting ? 'block' : 'none' }}>
          <InputLabel htmlFor="upload-field" shrink={false} sx={{ padding: 0, margin: 0, marginTop: 1, transform: "none", width: "100%", maxWidth: "100%" }}>
            <Button
              onClick={() => uploadFieldRef.current.focus()}
              variant="outlined"
              color="secondary"
              fullWidth
              disableElevation
              disabled={submitting}
              sx={{ background: theme.palette.background.paper }}>
              {files?.length <= 0 && <CameraAltSharp sx={{ marginRight: ".25em", color: theme.palette.primary.dark }} />}
              {files?.length <= 0 ? `Upload your photos/videos!` : `${files.length} files ready to upload...`}
            </Button>
          </InputLabel>
          <Input disabled={submitting} onChange={filesChanged} id="upload-field" fullWidth type="file" inputProps={{ ref: uploadFieldRef, multiple: "multiple", accept: "image/*,video/*" }} />
        </FormControl>
        {submitting && !uploadsComplete && <CircularProgress size={46} thickness={5} sx={{ marginTop: 0, marginBottom: 1 }} variant="determinate" value={uploadProgress()} />}
        {uploadsComplete && <CheckCircle color="primary" sx={{ width: "2.25em", height: "2.25em" }} />}
        {
          files?.length > 0 &&
          <Button
            variant="contained"
            fullWidth
            disableElevation
            color="secondary"
            disabled={submitting}
            onClick={startUpload}
            sx={{ marginTop: 2, background: theme.palette.primary.main, "&:hover": { color: theme.palette.primary.dark, background: theme.palette.primary.light } }}>
            {!submitting ? `Start upload!` : (!uploadsComplete ? `Uploading ${currentFile} of ${files.length}...` : `All done - thank you! 🎉`)}
          </Button>
        }
      </Grid>
      <Grid item xs={0} sm={3} />
      {files?.length > 0 && (!submitting || uploadsComplete) &&
        <>
          <Grid item xs={12} sm={6} textAlign="right">
            <Button variant="text" onClick={resetForm} sx={{ textDecoration: 'underline !important', fontSize: '0.9em', letterSpacing: '0em', color: theme.palette.primary.dark, background: theme.palette.background.default }}>
              {uploadsComplete ? `Upload more` : `Reset form`}
            </Button>
          </Grid>
        </>}
    </Grid>
  )
}

export default Upload;